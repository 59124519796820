var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    't-item',
    +_vm.item.day == 0
      ? 'yel'
      : _vm.item.collectionReminderType === 'OVERDUE_REMIND'
      ? 'red'
      : 'blu',
  ],on:{"click":function($event){_vm.$router.push(
      `/caseList?${_vm.qs.stringify({
        day: _vm.item.startDay,
        startDay: _vm.item.startDay,
        endDay: _vm.item.endDay,
        tipsStatus: _vm.item.collectionReminderType,
      })}`
    )}}},[_c('div',{staticClass:"n-t"},[_c('span',{staticClass:"n"},[_vm._v(_vm._s({ OVERDUE_REMIND: "已逾期", EXPIRATION_REMIND: "即将到期" }[ _vm.item.collectionReminderType ]))]),_c('span',{staticClass:"t"},[_vm._v(_vm._s({ OVERDUE_REMIND: `逾期 ${_vm.item.startDay}-${_vm.item.endDay} 天`, EXPIRATION_REMIND: _vm.item.startDay > 0 ? `${_vm.item.startDay}天后到期` : "今日账单日", }[_vm.item.collectionReminderType]))])]),(_vm.type == 1)?_c('div',{staticClass:"val"},[_vm._v(" "+_vm._s(_vm.columnAmount(_vm.item.amount))+" "),_c('span',[_vm._v("万元")])]):_c('div',{staticClass:"val"},[_vm._v(" "+_vm._s(_vm.columnAmount(_vm.item.count, { fixed: 0 }))+" "),_c('span',[_vm._v("笔")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }