<template>
  <div
    :class="[
      't-item',
      +item.day == 0
        ? 'yel'
        : item.collectionReminderType === 'OVERDUE_REMIND'
        ? 'red'
        : 'blu',
    ]"
    @click="
      $router.push(
        `/caseList?${qs.stringify({
          day: item.startDay,
          startDay: item.startDay,
          endDay: item.endDay,
          tipsStatus: item.collectionReminderType,
        })}`
      )
    "
  >
    <div class="n-t">
      <span class="n">{{
        { OVERDUE_REMIND: "已逾期", EXPIRATION_REMIND: "即将到期" }[
          item.collectionReminderType
        ]
      }}</span>
      <span class="t">{{
        {
          OVERDUE_REMIND: `逾期 ${item.startDay}-${item.endDay} 天`,
          EXPIRATION_REMIND:
            item.startDay > 0 ? `${item.startDay}天后到期` : "今日账单日",
        }[item.collectionReminderType]
      }}</span>
    </div>
    <div class="val" v-if="type == 1">
      {{ columnAmount(item.amount) }}
      <span>万元</span>
    </div>
    <div class="val" v-else>
      {{ columnAmount(item.count, { fixed: 0 }) }}
      <span>笔</span>
    </div>
  </div>
</template>
<script>
import qs from "qs";
import { columnAmount } from "@/utils/tool";

export default {
  props: {
    item: Object,
    type: Number,
  },
  data() {
    return {
      qs,

      //
    };
  },
  methods: {
    columnAmount,

    //
  },
};
</script>
<style lang="scss" scoped>

.t-item {
  width: 340px;
  min-width: 340px;
  height: 164px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  border: 2px solid rgba(151, 151, 151, 0.22);
  margin-bottom: 24px;
  &:nth-child(2n) {
    margin-left: 22px;
  }
  padding: 0 24px;
  .n-t {
    height: 28px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .n {
      font-size: 28px;
      line-height: 28px;
      font-weight: bold;
      color: #333;
    }
    .t {
      font-size: 24px;
      line-height: 24px;
    }
  }
  .val {
    font-size: 40px;
    line-height: 36px;
    margin-top: 36px;
    span {
      font-size: 24px;
      color: #666666;
      line-height: 24px;
    }
  }
  &.red {
    background: linear-gradient(
      to bottom,
      #ffe4e4 0%,
      #ffffff 37%,
      #ffffff 100%
    );
    .n-t .t {
      color: #ff5252;
    }
    .val {
      color: #ff5252;
    }
  }
  &.yel {
    background: linear-gradient(
      to bottom,
      #fff0e4 0%,
      #ffffff 37%,
      #ffffff 100%
    );
    .n-t .t {
      color: #ff9800;
    }
    .val {
      color: #ff9800;
    }
  }
  &.blu {
    background: linear-gradient(
      to bottom,
      #e4f5ff 0%,
      #ffffff 37%,
      #ffffff 100%
    );
    .n-t .t {
      color: #1890ff;
    }
    .val {
      color: #1890ff;
    }
  }
}

</style>